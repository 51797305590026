import React, { useEffect, useState } from 'react';
import lightFlash from '../assets/vid/knowtifi-light-flash.mp4';

const VideoBackground = ( props:any ) => {
    const { } = props;

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize)
    }, []);

    return (
        <video className={dimensions.width > 990 ? "fit-width" : "fit-height"} src={lightFlash} autoPlay={true}
               controls={false} muted={true} loop={true} playsInline={true}>911 Switch Flashing Lights</video>
    )
}

export default VideoBackground;

