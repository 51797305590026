import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import SmartContainer from '../layout/SmartContainer';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const AboutUs = ( props:any ) => {
    const { } = props;
    
    return (
        <div>
            <Helmet>
                <meta name="description" content="At knowtifi, our goal is to give you and your family the peace of mind that comes with knowing loved ones are safe." />
                <title>knowtifi : About Us</title>
                <link rel="canonical" href="https://knowtifi.com/about-us"/>
            </Helmet>
            <div id="splash-sub" className="small about">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <div className="splash-text">
                                <h2 className="k">Taking Care of You and Your Family</h2>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="section-padding">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{ marginBottom:'30px'}}><span
                                className="k">The App You Need</span><br/>But We Hope Never Have to Use</h2>
                            <div className="columns-2">
                                <p>At knowtifi, our goal is to give you and your family the peace of mind that comes
                                    with knowing loved ones are safe. We have families too and that’s why we created the
                                    knowtifi app and 911Switch – to make sure emergency help is on the way and family
                                    and friends could be alerted about a loved one who needs help.</p>
                                <p>Here at knowtifi, we have kids and older parents. We want to know they’re safe and we
                                    want to know if they need help now, not later.</p>
                                <p>That’s why we created the knowtifi app – to dispatch emergency crews and to let
                                    family and friends know at the same time.</p>
                                <p>And with our <Link to={'/911switch'}>911Switch</Link> which enables house lights to blink
                                    rapidly, emergency responders will be able to tell where the emergency is, helping
                                    them get their faster.</p>
                                <p>Thank you for trusting us to keep your family safe.</p>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
        </div>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(AboutUs);

