import React from 'react';

import { Route, Routes } from 'react-router-dom';
import SiteWrapper from './layout/SiteWrapper';
import useScrollToTop from './hooks/useScrollToTop';
import Home from './screens/Home';
import Knowtifi from './screens/Knowtifi';
import S911switch from './screens/S911switch';
import AboutUs from './screens/AboutUs';
import News from './screens/News';
import NewsItem from './screens/NewsItem';
import KnowtifiVsTraditional from './screens/KnowtifiVsTraditional';
import Faqs from './screens/Faqs';
import ContactUs from './screens/ContactUs';
import Buy from './screens/Buy';


function App() {
    useScrollToTop();
    return (
        <SiteWrapper>
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="/knowtifi" element={<Knowtifi />}/>
                <Route path="/911switch" element={<S911switch />}/>
                <Route path="/911switch/buy" element={<Buy />}/>
                <Route path="/about-us" element={<AboutUs />}/>
                <Route path="/news/:slug" element={<NewsItem />}/>
                <Route path="/news/knowtifi-vs-traditional-systems" element={<KnowtifiVsTraditional />}/>
                <Route path="/news" element={<News />}/>
                <Route path="/faqs" element={<Faqs />}/>
                <Route path="/contact" element={<ContactUs />}/>
            </Routes>
        </SiteWrapper>
    );
}

export default App;
